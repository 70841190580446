import React from 'react'
import { css } from '@emotion/core'
import { font, color, mediaQuery, smartPhone } from '../constants/styles'
import { Link } from 'gatsby'
import { FormBtn } from './FormBtn'
import { MainContentsCard } from './MainContentsCard'
import { AddContentsCard } from './AddContentsCard'
import { AddContentsCard2 } from './AddContentsCard2'
import home22 from '../images/home-2-2.png'


const wrapper = css`
  max-width: 1480px;
  width: 100%;
  padding-top: 169px;
  ${mediaQuery[smartPhone]} {
    padding: 40px 0 90px 0;
  }
`

const pcContainer = css`
  width: 90%;
  margin: 0 auto;
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`

const spContainer = css`
  display: none;
  width: 90%;
  margin: 0 auto;
  ${mediaQuery[smartPhone]} {
    display: block;
  }
`

const maincontainer = css`
  max-width: 343px;
  ${mediaQuery[smartPhone]} {
    padding-bottom: 40px;
  }
`

const flexTop = css`
  display: flex;
  justify-content: space-between;
`

const mainTitle = css`
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.subtitleSize};
  color: ${color.black};
  ${mediaQuery[smartPhone]} {
    font-size: 18px;
  }
`

const mainTitleRed = css`
  font-family: 'Noto Serif JP', serif;
  font-size: 30px;
  color: ${color.mainL};
  line-height: 43px;
  ${mediaQuery[smartPhone]} {
    font-size: 18px;
    line-weight: 26px;
  }
`

const border = css`
  width: 220px;
  height: 1px;
  background-color: ${color.gray};
  margin: 24px 0;
  ${mediaQuery[smartPhone]} {
    margin: 16px 0;
  }
`

const mainText = css`
  display: block;
  padding-bottom: 32px;
  font-size: ${font.textSize};
  color: ${color.black};
  line-height: 160%;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
  }
`

const imageAria = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 162px;
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`
const imageAria2 = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  ${mediaQuery[smartPhone]} {
    display: none;
  }
`

const mainContentsCardWrapper = css`
  max-width: 340px;
`

const mainContentsCardLogo = css`
  width: 55px;
  height: 55px;
  background-image: url(${home22});
  background-size: cover;
  ${mediaQuery[smartPhone]} {
    width: 30px;
    height: 30px;
    margin: 16px 0;
  }
`

const mainContentsCardMainText = css`
  display: block;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.subtitleSize};
  color: ${color.mainD};
  line-height: 36px;
  ${mediaQuery[smartPhone]} {
    font-size: 18px;
    line-height: 150%;
  }
`

const mainContentsCardBorder = css`
  width: 220px;
  height: 1px;
  background-color: ${color.gray};
  margin: 12px 0;
  ${mediaQuery[smartPhone]} {
    margin: 16px 0;
  }
`

const mainContentsCardSubText = css`
  display: block;
  padding-bottom: 50px;
  font-size: ${font.textSize};
  color: ${color.black};
  line-height: 160%;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
  }
`

const formArea = css`
  width: 314px;
  height: 38px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color.mainL};
  color: ${color.white};
  line-height: 12px;
  border-sizing: border-box;
  border-radius: 4px;
  transition: 0.25s;
  :hover {
    background-color: ${color.black};
    cursor: pointer;
  }
`

const tri = css`
  display: block;
  font-size: 10px;
  line-height: 12px;
  padding-right: 8px;
`

const formTitle = css`
  font-size: 10px;
  line-height: 12px;
`

export const MainContents = () => {
  return (
    <div css={wrapper}>
      <div css={pcContainer}>
        <div css={flexTop}>
          <div css={maincontainer}>
            <p css={mainTitle}>
              <span css={mainTitleRed}>“継承・開業”</span>のことなら<br></br>
              当組合にお任せください
            </p>
            <div css={border}></div>
            <p css={mainText}>
              奈良県での新規事業や事業継承をお考えの先生に、
              ご要望をヒアリングさせていただき、先生のスタイル合わせた開業計画をご提案させていただきます。
              まずは当組合までお気軽にご相談ください。
            </p>
            <Link to={`/Contact/`}>
              <FormBtn title="お問い合わせ" />
            </Link>
          </div>
          <AddContentsCard
            mainText1="新規開業・事業承継を"
            mainText2="お考えの方はこちら"
            subText="開業に関する情報などを優先的に配信させていただきます。"
            link="https://forms.gle/A4kJfHRyKdqJuQhx6"
            btnText="開業サポート登録"
          />
          <AddContentsCard2
            mainText1="事業譲渡を"
            mainText2="お考えの方はこちら"
            subText="事業継承に関するご相談やサポートをさせていただきます。"
            link="https://forms.gle/rVyki2mz5wSzYZ7D9"
            btnText="事業継承サポート登録"
          />
        </div>
        <div css={imageAria2}></div>
        <div css={flexTop}>
          <MainContentsCard
            mainText="開業物件検索"
            subText="奈良県内の公開物件を随時ご紹介させていただきます。"
            link="/OpeningList/"
          />
          <MainContentsCard
            mainText="開業支援"
            subText="開業における課題をひとつひとつクリアにし、成功へと導きます"
            link="/OpeningSupport/"
          />
          <div css={mainContentsCardWrapper}>
            <div css={mainContentsCardLogo}></div>
            <p css={mainContentsCardMainText}>医療機器・医療材料</p>
            <div css={mainContentsCardBorder}></div>
            <p css={mainContentsCardSubText}>
              開業に必要な医療機器や材料に関してもおすすめのメーカーをご紹介いたします。
            </p>
          </div>
        </div>
      </div>
      <div css={spContainer}>
        <div css={maincontainer}>
          <p css={mainTitle}>
            <span css={mainTitleRed}>“継承・開業”</span>のことなら<br></br>
            当組合にお任せください
          </p>
          <div css={border}></div>
          <p css={mainText}>
            奈良県での新規事業や事業継承をお考えの先生に、
            ご要望をヒアリングさせていただき、先生のスタイル合わせた開業計画をご提案させていただきます。
            まずは当組合までお気軽にご相談ください。
          </p>
          <Link to={`/Contact/`}>
            <div css={formArea}>
              <p css={tri}>▶</p>
              <p css={formTitle}>お問い合わせはこちら</p>
            </div>
          </Link>
        </div>
        <AddContentsCard
          mainText1="新規開業・事業承継を"
          mainText2="お考えの方はこちら"
          subText="開業に関する情報などを優先的に配信させていただきます。"
          link="https://forms.gle/A4kJfHRyKdqJuQhx6"
          btnText="開業サポート登録"
        />
        <AddContentsCard2
          mainText1="事業譲渡を"
          mainText2="お考えの方はこちら"
          subText="事業継承に関するご相談やサポートをさせていただきます。"
          link="https://forms.gle/rVyki2mz5wSzYZ7D9"
          btnText="事業継承サポート登録"
        />
        <MainContentsCard
          mainText="開業支援"
          subText="開業における課題をひとつひとつクリアにし、成功へと導きます。"
          link="/OpeningSupport/"
        />
        <MainContentsCard
          mainText="開業物件検索"
          subText="奈良県内の公開物件を随時ご紹介させていただきます。"
          link="/OpeningList/"
        />
        <div css={mainContentsCardWrapper}>
          <div css={mainContentsCardLogo}></div>
          <p css={mainContentsCardMainText}>医療機器・医療材料</p>
          <div css={mainContentsCardBorder}></div>
          <p css={mainContentsCardSubText}>
            開業に必要な医療機器や材料に関してもおすすめのメーカーをご紹介いたします。
          </p>
        </div>
      </div>
      <div css={imageAria}></div>
    </div>
  )
}
